*{
    margin: 0;
    padding: 0;
}

.loaderAnimation{
    width: 180px;
    height: 150px;
}
.topFixedBanner{
    background-image: url("../images/image.jpg");
    min-height: 1000px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.topBannerOverlay{
    position: absolute;
    min-height: 1000px;
    width: 100%;
    background: rgba(0,0,0,0.4);
}
.topContent{
    margin-top: 20rem;

}
.topTitle{
    position: relative;
}
.topTitle h1{

    font-family: 'Montserreat',sans-serif;
    font-weight: 700;
    color: #ffffff;
    font-size: 40px;


}

.fbIcon{
    color: #ffffff;

    font-family: 'Montserreat',sans-serif;
    padding: 8px 10px;
    font-size: 20px;
    font-weight: 800;

}
.fbIcon:hover{

    background:#16a085;;
    color: #1a1e21;
    border-radius:50%!important;
    cursor: pointer;

}
.DotIcon{
   
    color: rgba(0,0,0,0.4);
    font-family: 'Montserreat',sans-serif;
    padding: 8px 10px;
    font-size: 25px;
    font-weight: 400;

}
.DotIcon:hover{
   
    background:#16a085;;
    color: #1a1e21;
    padding: 8px 8px;
    cursor: pointer;
    border-radius:75%!important;

}

.animated-paragraph {

    line-height: 20px;
    font-family: 'Montserreat', sans-serif;
    font-weight: 700;
    color: #ffffff;
    font-size: 22px;
    animation: 3s linear 1s infinite alternate slidein;

}

.topSubTitle{

    font-family: 'Raleway',sans-serif;
    font-weight: 300;
    color: #ffffff;
    font-size: 35px;
    margin-bottom: 30px;


}

.socialLink{
    color:#000000;
    width:25px;
    height: 25px;
    padding: 2px 2px 0px 0px;

}
.search{
    margin-left: 5px;
}
.search:focus{
    box-shadow: "0 0 10px #16a085";
}
.form-control:focus{
    border-color: #16a085 !important;
    box-shadow: 0 0 0 0.1rem #8ebdb1 !important;
  }



.navTitle{
    font-family: 'Montserreat',sans-serif;
    color: #ffffff !important;
    font-weight: 700;
    font-size: 24px;
}
.navTitleScroll{
    font-family: 'Montserreat',sans-serif;
    color: #0073E6 !important;
    font-weight: 700;
    font-size: 24px;
}
.navLogo{
    height:50px;
    width:50px;

}

.navMenu{
    
    margin-left:5rem;
    margin-right: 5rem;

}
.navBackground{
    -webkit-transition:background-color 400ms linear ;
    -moz-transition:background-color 400ms linear ;
    -o-transition:background-color 400ms linear ;
    transition:background-color 400ms linear ;
    background-color: rgba(00,00,00,0.0) !important;
}
.navBackgroundScroll{
    -webkit-transition:background-color 400ms linear ;
    -moz-transition:background-color 400ms linear ;
    -o-transition:background-color 400ms linear ;
    transition:background-color 400ms linear ;
    background-color: #ffffff !important;
}
.navTitle{
    margin-left: 4rem;   
    font-size: 25px !important;
    text-decoration: none !important;
    font-family: 'Montserreat',sans-serif;
    font-weight: 700;
    color: #16a085 !important ;
}
.navItem{
    font-size: 15px;
    padding: 0px 8px;
    text-decoration: none !important;
    font-family: 'Montserreat',sans-serif;
    font-weight: 500;
    color: #16a085 !important ;
}

.navItem:hover{

    border-bottom: 2px  solid rgba(22, 160, 133,0.7);


}

.navItemScroll{
    font-size: 15px;
    text-decoration: none !important;
    font-family: 'Montserreat',sans-serif;
    font-weight: 500;
    color: #000000 !important;
}

.navItemScroll:hover{

    border-bottom: 2px  solid rgb(22, 160, 133);


}
.about{
    background:#222f3e;
    
  

}
.aboutContain{
    margin-top:8rem;
    padding-bottom: 6rem;
}

.AboutName{
    margin-top:2rem;
    font-family: 'Montserreat',sans-serif;
    font-size: 30px;
    color:#fff;
    font-weight: 800;
}
.AboutSubName{
    margin-top:1rem;
    font-family: 'Raleway',sans-serif;
    font-size: 20px;
    color:#C5C7CE;
    font-weight:800;
}
.AboutDescription{
  
    color:#C5C7CE;
    font-family: 'Raleway',sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.aboutLink{
    color: #fff;
    padding:10px;
    display: inline-block;
    text-decoration: none;
    font-family: 'Raleway',sans-serif;
    font-size: 16px;
    font-weight: 400;
    border: 2px solid rgba(22, 160, 133);

}

.aboutLink:hover{
    text-decoration: none;
    display: inline-block;
    color:#000!important;
    background:#16a085;
    opacity: 0.8;
    border-radius:2px ;
    cursor: pointer;
    transition:2s;

}
.AboutDot{
    margin-left: 2px;
}
.aboutImage{
         margin-top: 2rem auto;
         padding: 10px 0px;
        
}
.aboutImage img{
    
    border-radius:5%;
    max-height: 500px;
    width:100%;
    cursor: pointer;
    box-shadow: 0 0 8px 0 rgb(147, 155, 153);
   
}

.services{
    background:#222f3e;
    

}
.ServicesContain{
    margin-top: 8rem;
}

.serviceCard{
    display:flex;
    flex-direction: column;
    margin: 5px 5px 5px 5px;
    height: 27rem;
    padding: 0px 0px 60px 0px;
    box-shadow: 0 0 4px 0 rgb(49, 164, 141);;
}
.serviceCard:hover{
    box-shadow: 0 0 8px 0 rgb(147, 155, 153);
    cursor: pointer;
}


.serviceMainTitle{
    color: #fff!important;
    padding: 70px 0px 0px 0px;
    font-family: 'Montserreat',sans-serif;
    font-size: 28px;
    font-weight: 600;
}
.serviceMainSubTitle{
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-family: 'Montserreat',sans-serif;
    font-size: 20px;
    font-weight: 600;
    color:#C5C7CE;

}

.hr{
    display:flex;
    width:100px;
    margin:0rem auto 3rem;
    border-style: inset;
    border-width: 5px;
    border-color:#16a085;
    

}

.serviceImg{
    display:flex;
    margin: 15px auto 0px;
    width:100px;
    border-radius: 100%;
    max-height:70px;
    background:rgb(7, 20, 17);

    

}

.serviceImg:hover {
    cursor: pointer;
    background:rgb(49, 164, 141);
}


.serviceName{
    margin-top: 1rem;
    font-family: 'Raleway',sans-serif;
    font-size: 20px;
    font-weight: 600;
    color:aliceblue;
}

.serviceDescription{
    color:#C5C7CE;
    font-family: 'Raleway',sans-serif;
    font-size: 14px;
    font-weight: 400;
    padding: 15px 10px;

}
.skill{
   margin-left: 1rem;
   background:#222f3e;


}
.SkillContain{
    margin-top:4rem;
}

.project{
    background:#222f3e;
  

}
.ProjectContain{
    margin-top: 4rem;
}
.projectMainTitle{
    color: #fff!important;
    padding: 70px 0px 0px 0px;
    font-family: 'Montserreat',sans-serif;
    font-size: 28px;
    font-weight: 600;
}

.projectCard{
    display:flex;
    flex-direction: column;
    background:rgb(49, 164, 141);
    margin: 5px 2px 5px 2px;
    height: 32rem;
    padding: 0px 0px 60px 0px;
    box-shadow: 0 0 4px 0 #222f3e;
}

.projectCard:hover{
    box-shadow: 0 0 8px 0 rgb(147, 155, 153);
    cursor: pointer;
}

.projectName{
    margin-top: 1rem;
    font-family: 'Raleway',sans-serif;
    font-size: 18px;
    font-weight: 600;
    color:#000;

}
.contact{
    background:#222f3e;
    
   
}


.ContactContain{
    margin-top: 6rem;
}
.contactTitle{
    text-align: justify;
    color:#16a085;
    font-weight: 700;
    margin-top: 5rem;
    margin-bottom: 1rem;
    font-family: 'Montserreat';

}
.contactText{
    color:#16a085;
    font-size: 25px;
}
.contactHr{
    display:flex;
    margin-bottom: 3rem;
    width:100px;
    border-style: inset;
    border-width: 3px;
    border-color:#16a085;
    

}
.form-control[type='text']{
    background-color: #222f3e;
    border-radius: 2px;
}
.form-control[type='email']{
    background-color: #222f3e;
    border-radius: 2px;
}
.form-control[type='text']:focus{
    background-color: #222f3e;
    border-radius: 5px;
}
.form-control[type='email']:focus{
    background-color: #222f3e;
    border-radius: 5px;
}

.contactLeft{
    
}
.leftAddress{
         color:aliceblue;
         text-align: justify;
         padding: 2px;
         font-size: 18px;

         
}

.leftAddress:hover{
    color:#16a085;
    cursor: pointer;

    
}

.faHouse{
   color:#16a085;
}
.btn-contact{
    width:100%;
  
   
}
.btn-contact Button{
    background-color: #16a085;
    width:100%;
    display: inline-block;
}